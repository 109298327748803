<template>
    <div class="px-3 py-2">
        <v-form
            ref="forcar"
            lazy-validation
            autocomplete="off"
        >
            <b-sidebar id="add_addHomeCalendar" aria-labelledby="sidebar-no-header-title" no-header backdrop width="850px" style="direction:ltr" right title="إضافة ملاحظة" shadow >
    <template #default="{ hide }">
        <div class="m-1 text-start" style="direction:rtl">
            <div style="width:140px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                <span>{{ lang.add_booking }}</span>
            </div>
            <div @click="hide" id="hideaddHomeCalendar" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                <span>{{ lang.close }}</span>
            </div>
        </div>
        <v-divider></v-divider>
        <v-row style="direction:rtl" class="m-2">
            <v-col cols="12" md="4">
                <label>{{lang.customerid}}</label>
                <b-form-input class="inborder"
                    v-model="customerid"
                    @change="getCustoemrs(1)"
                    />
            </v-col>
            <v-col cols="12" md="4">
                <label>{{lang.mobile}}</label>
                <b-form-input class="inborder"
                    v-model="mobile"
                    @change="getCustoemrs(2)"
                    />
            </v-col>
            <v-col cols="12" md="4" sm="12">
                <label>{{lang.customer_type}}</label>
                <b-input-group>
                    <b-form-select class="selborder"
                        v-model="customer_type"
                        :options="customerTypes"
                        />
                    <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                        <i class="fas fa-arrow-down"></i>
                    </b-input-group-append>
                </b-input-group>
            </v-col>
            <v-col cols="12" md="4" sm="12">
                <label>{{lang.choose_custoemr}}</label>
                <b-input-group>
                    <b-form-select class="selborder"
                        v-model="chose_cus"
                        :options="customersList"
                        @change="choseCust()"
                        />
                    <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                        <i class="fas fa-arrow-down"></i>
                    </b-input-group-append>
                </b-input-group>

            </v-col>
            <v-col cols="12" md="4" sm="12">
                <label>{{lang.full_name}}</label>
                <b-form-input class="inborder"
                    v-model="full_name"
                    :options="customerTypes"
                    />
            </v-col>
            <v-col cols="12" md="6" sm="12" v-if="customer_type == 2">
                <label>{{lang.company_name}}</label>
                <b-form-input class="inborder"
                    v-model="company_name"
                    />
            </v-col>
            <v-col cols="12" md="6" sm="12" v-if="customer_type == 2">
                <label>{{lang.vat_number}}</label>
                <b-form-input class="inborder"
                    v-model="vatid"
                    />
            </v-col>
            <v-col cols="12" md="6" sm="12" v-if="customer_type == 2">
                <label>{{lang.crt_number}}</label>
                <b-form-input class="inborder"
                    v-model="crt_number"
                    />
            </v-col>
            <v-col cols="12" md="6" sm="12" v-if="customer_type == 2">
                <label>{{lang.address}}</label>
                <b-form-input class="inborder"
                    v-model="address"
                    />
            </v-col>
            <v-col cols="12" md="6" sm="12" v-if="customer_type == 3">
                <label>{{lang.entity_name}}</label>
                <b-form-input class="inborder"
                    v-model="entity_name"
                    />
            </v-col>
            <v-col cols="12" md="6" sm="12" v-if="customer_type == 3">
                <label>{{lang.branch_name}}</label>
                <b-form-input class="inborder"
                    v-model="branch_name"
                    />
            </v-col>
            <v-col cols="12" md="2" sm="12">
                <label>{{lang.estimated_price}}</label>
                <b-form-input class="inborder"
                    v-model="ac_price"
                    />
            </v-col>
            <v-col cols="12" md="2" sm="12">
                <label>{{lang.advanced_payment}}</label>
                <b-form-input class="inborder"
                    v-model="advanced_paid"
                    />
            </v-col>
            
            <v-col cols="12" md="12" sm="12">
                <label>{{lang.work_name}}</label>
                <b-form-textarea class="inborder"
                    v-model="task_description"
                    :label="lang.task_description"
                    value=""
                    required
                    ></b-form-textarea>
            </v-col>
            <d-col cols="12">
                <v-row>
                    <v-col cols="12" md="4" sm="12">
                        <label>{{lang.work_type}}</label>
                        <b-input-group>
                            <b-form-select class="selborder"
                                v-model="calendar_type"
                                :options="calendarTypes"
                                />
                            <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                                <i class="fas fa-arrow-down"></i>
                            </b-input-group-append>
                        </b-input-group>
                    </v-col>
                    <v-col cols="12" md="3" sm="12" v-if="$store.state.licenseType.cars">
                        <label>{{lang.plate_number}}</label>
                        <div class="plateNumber">
                            <b-form-input class="inborder" v-model="pl.c1" style="background:#fff700 !important;width:30px;background:#efefef;" />
                            <b-form-input class="inborder" v-model="pl.c2" style="border-inline:none !important;background:#fff700 !important;width:30px;background:#efefef;" />
                            <b-form-input class="inborder" v-model="pl.c3" style="border-inline-end:none !important;background:#fff700 !important;width:30px;background:#efefef;" />
                            <b-form-input class="inborder" v-model="pl.text" style="background:#fff700 !important;width:60px;background:#efefef;" />
                        </div>
                    </v-col>
                    <v-col cols="12" md="3" sm="12" v-if="$store.state.licenseType.cars">
                        <label>{{lang.model}}</label>
                        <b-form-input class="inborder"
                            v-model="car_model"
                            />
                    </v-col>
                    <v-col cols="12" md="2" sm="12" v-if="$store.state.licenseType.cars">
                        <label>{{ lang.car_year }}</label>
                        <b-form-input class="inborder"
                            v-model="car_year"
                            />
                    </v-col>
                    <v-col cols="12" md="3" sm="12" v-if="$store.state.licenseType.cars">
                        <label>{{lang.car_color}}</label>
                        <b-form-input class="inborder"
                            v-model="car_color"
                            />
                    </v-col>
                    <v-col cols="12" md="4" sm="12" v-if="$store.state.licenseType.cars">
                        <label>{{lang.vin_number}}</label>
                        <b-form-input class="inborder"
                            v-model="car_vin"
                            />
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                        <label>{{lang.paytypes}}</label>
                        <b-input-group>
                            <b-form-select class="selborder"
                                v-model="paytype"
                                :options="paytypes"
                                />
                            <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                                <i class="fas fa-arrow-down"></i>
                            </b-input-group-append>
                        </b-input-group>
                    </v-col>
                </v-row>
            </d-col>
            <v-col
          cols="12"
          md="10"
          sm="12">
            <v-row>
                <v-col cols="12" md="4">
                    <label>{{ chose_claendar_date }}</label>
                    <b-form-input type="date" class="inborder" v-model="calendar_date" />
                </v-col>
                <v-col cols="12" md="4">
                    <label>{{ chose_claendar_time }}</label>
                    <b-form-input type="time" class="inborder" v-model="calendar_time" />
                </v-col>
            </v-row>
            <v-row style="display:none;">
              <v-col cols="12" md="6" sm="12">
                <label>{{lang.start_date}}</label>
                <b-input-group>
                  <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="sd.day" :options="daysList"/>
                  <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;border-right:none !important;" v-model="sd.month" :options="monthsList" />
                  <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="sd.year" :options="yearsList" />
                </b-input-group>
              </v-col>
              <!-- <v-col cols="12" md="6" sm="12">
                <label>{{lang.start_time}}</label>
                <b-input-group>
                  <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="sd.seconds" :options="secondsList" />
                  <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="sd.minutes" :options="minutesList" />
                  <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="sd.hours" :options="hoursList" />
                </b-input-group>
              </v-col> -->
              <v-col cols="12" md="6" sm="12">
                <label>{{lang.dead_line}}</label>
                <b-input-group>
                  <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="ed.day" :options="daysList" />
                  <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;border-right:none !important;" v-model="ed.month" :options="monthsList" />
                  <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="ed.year" :options="yearsList" />
                </b-input-group>
              </v-col>
              <!-- <v-col cols="12" md="6" sm="12">
                <label>{{lang.deadline_time}}</label>
                <b-input-group>
                  <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="ed.seconds" :options="secondsList" />
                  <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="ed.minutes" :options="minutesList" />
                  <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="ed.hours" :options="hoursList" />
                </b-input-group>
              </v-col> -->
            </v-row>
        </v-col>
            <v-col cols="12" md="6" sm="12">
                <label>{{lang.worker_name}}</label>
                <b-input-group>
                    <b-form-select class="selborder"
                        v-model="task_to"
                        :options="userslist"
                        />
                    <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                        <i class="fas fa-arrow-down"></i>
                    </b-input-group-append>
                </b-input-group>
            </v-col>
            <!-- <v-col cols="12" md="4" sm="12">
                <label>{{lang.plate_number}}</label>
                <b-form-select class="selborder"
                    v-model="plate_number"
                    :options="plates"
                    required
                    />
            </v-col> -->
            <v-col cols="12" md="6" sm="12">
                <label>{{lang.status}}</label>
                <b-input-group>
                    <b-form-select class="selborder"
                        v-model="status"
                        :options="statusOptions"
                        />
                    <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                        <i class="fas fa-arrow-down"></i>
                    </b-input-group-append>
                </b-input-group>
            </v-col>
        </v-row>
    </template>
                <template #footer="">
                    <div class="d-flex text-light align-items-center px-3 py-2">
                    <b-button type="button" @click="addTasks()" variant="success" class="ma-2" style="width:100px;">إضافة</b-button>
                    </div>
                </template>
            </b-sidebar>
        </v-form>
        <vue-snotify></vue-snotify>
    </div>
</template>

<script>
import axios from 'axios'
import {SnotifyPosition} from 'vue-snotify'
export default{
    data() {
        return {
            pl: {
                c1: '',
                c2: '',
                c3: '',
                text: ''
            },
            car_model: '',
            car_year: '',
            car_color: '',
            car_vin: '',
            ac_price: '',
            calendar_date: '',
            calendar_time: '',
            sd: {
                day: 1,
                month: 1,
                year: (new Date()).getFullYear(),
                seconds: '00',
                minutes: (new Date()).getMinutes(),
                hours: (new Date()).getHours(),
            },
            ed: {
                day: (new Date()).getDate(),
                month: (new Date()).getMonth() + 1,
                year: (new Date()).getFullYear(),
                seconds: '00',
                minutes: (new Date()).getMinutes(),
                hours: (new Date()).getHours(),
            },
            chose_cus: 0,
            customerid:'',
            task_description: '',
            start_date: '',
            start_time: '',
            dead_line: '',
            dead_lin_time: '',
            task_to: '',
            userslist: [],
            user: {},
            status: '1',
            statusOptions: [],
            // plate_number: '',
            customersList: [
                {value: 0,text: 'عميل جديد'}
            ],
            mobile: '',
            full_name: '',
            customer_type: 1,
            company_name: '',
            vatid: '',
            crt_number: '',
            address: '',
            entity_name: '',
            branch_name: '',
            allCustomer: [],
            advanced_paid: 0,
            paytype: 1,
            paytypes: [],
            calendar_type: 1,
            calendarTypes: [],
            app_time: '',
        }
    },
    computed: {
        plate_number: function(){
            let t = this.pl.c1;
            if(this.pl.c2 != ''){
                t = t + " " + this.pl.c2
            }
            if(this.pl.c3 != ''){
                t = t + " " + this.pl.c3
            }
            if(this.pl.text != ''){
                t = t + " " + this.pl.text
            }
            return t;
        },
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                    return this.$store.state.lang.en;
                }else{
                    return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        plates: function(){
            const t = [];
            for(let i=0;i<this.$parent.cars.length;i++){
                t.push({text: this.$parent.cars[i].plate_number,value: this.$parent.cars[i].plate_number})
            }
            return t;
        },
        customerTypes: function(){
            return [
                {text: this.lang.individual_client,value: 1},
                {text: this.lang.company_client,value: 2},
                {text: this.lang.government_clinet,value: 3},
            ]
        },
        sdate: function() {
            return (new Date(this.sd.year,this.sd.month-1, this.sd.day+1,this.sd.hours,this.sd.minutes,this.sd.seconds)).toISOString().substring(0,10);
        },
        edate: function() {
            return (new Date(this.ed.year,this.ed.month-1, this.ed.day+1,this.ed.hours,this.ed.minutes,this.ed.seconds)).toISOString().substring(0,10);
        },
      yearsList: function(){
        const start_year = this.$store.state.licenseType.startYear;
        let t = [{text: this.lang.year, value: -1}];
        for(let i=0;i<100;i++){
          if(parseInt(start_year) + i <= (new Date()).getFullYear()){
            t.push({text: start_year + i, value: start_year+i})
          }
          
        }
        return t
      },
      daysList: function(){
        return [
          {text: this.lang.day, value: -1},
          {text: 1, value: 1},
          {text: 2, value: 2},
          {text: 3, value: 3},
          {text: 4, value: 4},
          {text: 5, value: 5},
          {text: 6, value: 6},
          {text: 7, value: 7},
          {text: 8, value: 8},
          {text: 9, value: 9},
          {text: 10, value: 10},
          {text: 11, value: 11},
          {text: 12, value: 12},
          {text: 13, value: 13},
          {text: 14, value: 14},
          {text: 15, value: 15},
          {text: 16, value: 16},
          {text: 17, value: 17},
          {text: 18, value: 18},
          {text: 19, value: 19},
          {text: 20, value: 20},
          {text: 21, value: 21},
          {text: 22, value: 22},
          {text: 23, value: 23},
          {text: 24, value: 24},
          {text: 25, value: 25},
          {text: 26, value: 26},
          {text: 27, value: 27},
          {text: 28, value: 28},
          {text: 29, value: 29},
          {text: 30, value: 30},
          {text: 31, value: 31},
        ]
      },
      monthsList: function(){
        return [
          {text: this.lang.chose_month, value: -1},
          {text: 1, value: 1},
          {text: 2, value: 2},
          {text: 3, value: 3},
          {text: 4, value: 4},
          {text: 5, value: 5},
          {text: 6, value: 6},
          {text: 7, value: 7},
          {text: 8, value: 8},
          {text: 9, value: 9},
          {text: 10, value: 10},
          {text: 11, value: 11},
          {text: 12, value: 12},
        ]
      },
      secondsList: function(){
        let t = [];
        for(let i=0;i<60;i++){
            let j = i;
            t.push({
                text: j < 10 ? "0"+j : j,
                value: j < 10 ? "0"+j : j
            })
        }
        return t;
      },
      minutesList: function(){
        let t = [];
        for(let i=0;i<60;i++){
            let j = i;
            t.push({
                text: j < 10 ? "0"+j : j,
                value: j < 10 ? "0"+j : j
            })
        }
        return t;
      },
      hoursList: function(){
        // console.log("aaaa" , (new Date()).getHours());
        let t = [];
        for(let i=0;i<23;i++){
            let j = i;
            t.push({
                text: j < 10 ? "0"+j : j,
                value: j < 10 ? "0"+j : j
            })
        }
        return t;
      }
    },
    methods: {
        getPayTypes(){
            const post = new FormData();
            post.append("type" , "getPayTypes");
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[customerid]',this.customerid);
            axios.post(
                this.$store.state.SAMCOTEC.r_path,post
            ).then((response) => {
                if(response.data.results.data.length > 0){
                    const res = response.data.results.data
                    // console.log("paytypes",res);
                    for(let i=0;i<res.length;i++){
                        this.paytypes.push(
                            {value: res[i].id,text: res[i].type_name}
                        );
                    }
                }
            })
        },
        choseCust(){
            if(this.chose_cus != 0){
                this.customerid = this.chose_cus;
                for(let i=0;i<this.allCustomer.length;i++){
                    if(this.allCustomer[i].id == this.chose_cus){
                        this.mobile = this.allCustomer[i].mobile;
                        this.full_name = this.allCustomer[i].full_name;
                        this.company_name = this.allCustomer[i].company_name;
                        this.customer_type = this.allCustomer[i].customer_type;
                        this.crt_number = this.allCustomer[i].crt_number;
                        this.vatid = this.allCustomer[i].vatid;
                        this.address = this.allCustomer[i].address;
                        this.entity_name = this.allCustomer[i].entity_name;
                        this.branch_name = this.allCustomer[i].branch_name;
                    }
                }
            }
        },
        getCustoemrs(id){
            if(id == 1) this.mobile = '';
            if(id == 2) this.customerid = '';
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type" , "getCustomers");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[mobile]',this.mobile);
            post.append('data[customerid]',this.customerid);
            axios.post(
                this.$store.state.SAMCOTEC.r_path,post
            ).then((response) => {
                if(response.data.results.data.length > 0){
                    const res = response.data.results.data
                    this.allCustomer = res;
                    this.customersList = [
                        {text: this.lang.choose_custoemr, value: 0}
                    ]
                    for(let i=0;i<res.length;i++){
                        if(i == 0){
                            this.customerid = res[i].id
                            this.chose_cus = res[i].id
                            this.choseCust()
                        }
                        let cusName = res[i].full_name;
                        if(res[i].customer_type == 2){
                            cusName = res[i].company_name;
                        }
                        if(res[i].customer_type == 3){
                            cusName = res[i].entity_name;
                        }
                        
                        this.customersList.push(
                            {value: res[i].id,text: cusName}
                        );
                    }
                }
            })
        },
        async getUsers(){
            const post = new FormData();
            post.append("type" , "getEmpsS");
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[status]',1);
            const response = await axios.post(this.$store.state.SAMCOTEC.r_path,post);
            
                const res = response.data.results.data.results;
                // console.log("asdfas",res);
                for(let i = 0;i < res.length;i++){
                    this.userslist.push({text: res[i].employee_name, value: res[i].id})
                    this.task_to = res[0].id;
                }
        },
        reset(){
            this.car_model = ''
            this.car_year = ''
            this.car_color = ''
            this.car_vin = ''
            this.ac_price = ''
            this.customerid = ''
            this.mobile = ''
            this.customer_type = 1
            this.full_name = ''
            this.company_name = ''
            this.vatid = ''
            this.crt_number = ''
            this.address = ''
            this.advanced_paid = ''
            this.task_description = ''
            this.customersList = [{text: this.lang.choose_custoemr, value: 0}]
        },
        async addTasks(){
            
            if(this.mobile == ''){
                let message = 'يحب اضافة رقم اتصال العميل';
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            if(this.full_name == ''){
                let message = 'اسم العميل مطلوب';
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            if(this.company_name == '' && this.customer_type == 2){
                let message = 'اسم الشركة/المؤسسة';
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            if(this.address == '' && this.customer_type == 2){
                let message = 'عنوان الشركة مطلوب';
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            if(this.entity_name == '' && this.customer_type == 3){
                let message = 'اسم الجهة الحكومية مطلوب';
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            if(this.branch_name == '' && this.customer_type == 3){
                let message = 'اسم الفرع مطلوب';
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            if(this.plate_number == ''){
                let message = 'رقم اللوحة مطلوب';
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            if(this.car_model == ''){
                let message = 'نوع السيارة مطلوب';
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            if(this.car_year == ''){
                let message = 'يجب اضافة تاريخ صنع السيارة';
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            if(this.task_description == ''){
                let message = 'تفاصيل العمل مطلوبة';
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            
            if(this.calendar_date == '' || this.calendar_time == ''){
                let message = this.lang.task_start_end_date_required;
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            
            const post = new FormData();
            post.append("type" , "addSamCalendars");
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[doc_type]", "warrantly")
            post.append("data[docid]",this.customerid);
            post.append("data[description]",this.task_description);
            post.append("data[start_date]",this.calendar_date);
            post.append("data[start_time]",this.calendar_time);
            post.append("data[deadline]",this.calendar_date);
            post.append("data[deadline_time]",this.calendar_time);
            post.append("data[userid]",this.task_to);
            post.append("data[status]",this.status);
            post.append('data[customerid]', this.customerid)
            post.append('data[customer_type]', this.customer_type)
            post.append('data[mobile]', this.mobile)
            post.append('data[company_name]', this.company_name)
            post.append('data[crt_number]', this.crt_number)
            post.append('data[vatid]', this.vatid)
            post.append('data[address]', this.address)
            post.append('data[entity_name]', this.entity_name)
            post.append('data[branch_name]', this.branch_name)
            post.append('data[advanced_paid]', this.advanced_paid == '' ? 0 : this.advanced_paid)
            post.append('data[paytype]', this.paytype)
            post.append('data[full_name]', this.full_name)
            post.append('data[calendar_type]', this.calendar_type)
            post.append('data[plate_number]', this.plate_number)
            post.append('data[car_model]', this.car_model)
            post.append('data[car_year]', this.car_year)
            post.append('data[car_color]', this.car_color)
            post.append('data[car_vin]', this.car_vin)
            post.append('data[ac_price]', this.ac_price)
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            )
            .then((response) => {
                const res = response.data;
                let message = 'تمت الاضافة بنجاح';
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                this.$parent.getCurrentCards();
                document.getElementById('hideaddHomeCalendar').click();
            });
            
            this.message = '';
        },
        getCurrent(){
            const post = new FormData();
            post.append("type" , 'getUsers');
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append('data[userid]','current');
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    this.user = res.results.data.results[0];
                    this.getUsers();
                }
            )
        },
        getStatus(status){
            const post = new FormData();
            post.append("type" , 'getStaticOptions');
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append('data[phrase]',status);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data.results.data;
                    for(let i=0;i<res.length;i++){
                        this.statusOptions.push({text: res[i][`name_`+this.lang.langname], value: res[i].value})
                    }
                }
            )
        },
        getCalendarTypes(status){
            const post = new FormData();
            post.append("type" , 'getStaticOptions');
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append('data[phrase]',status);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data.results.data;
                    for(let i=0;i<res.length;i++){
                        this.calendarTypes.push({text: res[i][`name_`+this.lang.langname], value: res[i].value})
                    }
                }
            )
        },
    },
    created() {
        this.getCurrent();
        this.getStatus('Calendar');
        this.getCalendarTypes('calendarTypes');
        this.getPayTypes()
    },
}
</script>