<template>
    <div class="px-3 py-2">
        <v-form
            ref="forcar"
            lazy-validation
        >
            <b-sidebar id="edit_calendar" aria-labelledby="sidebar-no-header-title" no-header backdrop width="1000px" style="direction:ltr" right title="إضافة ملاحظة" shadow >
                <template #default="{ hide }">
                    <div class="m-1 text-start" style="direction:rtl">
                        <div style="width:140px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                            <span>{{lang.edit_calendar}}</span>
                        </div>
                        <div @click="hide" id="hideEditCalendar" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                            <span>{{lang.close}}</span>
                        </div>
                    </div>
                    <v-divider></v-divider>
                    <v-row style="margin:auto 5px;">
                        <v-col cols="12">
                            <div class="myBtn backBlack" :style="`direction:${lang.dir}`">
                                <div class="btnRow c_red"  @click="resetAllValues()" v-b-toggle.change_calendar_date>{{lang.edit_calendar}}</div>
                                <div class="btnRow c_darkblue" @click="resetAllValues()" v-b-toggle.addqut_calendar_date>{{ lang.quotations }}</div>
                                <div class="btnRow c_darkslategrey" @click="resetAllValues()" v-b-toggle.addcard_calendar_date>{{ lang.add_new_card }}</div>
                                <div class="btnRow c_darkorchid" @click="printPdf()">{{lang.print}}</div>
                                <!-- <div class="btnRow c_blue" @click="aupdate()" v-b-toggle.update_customer>تحميل الموعد</div> -->
                                <img :src="`../whatsappicon.png`" style="margin-top:11px;border:2px solid #fff !important;width:100px;height: 35px;border-radius: 5px;cursor:pointer" @click="openWhatsAPP()" />
                            </div>
                        </v-col>
                        <v-col cols="12" style="direction:rtl">
                            <v-simple-table>
                                <thead>
                                    <tr>
                                        <th class="text-cetner backBlack" style="text-align:center;">#</th>
                                        <th class="text-cetner backBlack" style="text-align:center;">{{ lang.start_date }}</th>
                                        <th class="text-cetner backBlack" style="text-align:center;">{{lang.start_time}}</th>
                                        <th class="text-cetner backBlack" style="text-align:center;">{{lang.end_date}}</th>
                                        <th class="text-cetner backBlack" style="text-align:center;">{{lang.deadline_time}}</th>
                                        <th class="text-cetner backBlack" style="text-align:center;">{{lang.advanced_payment}}</th>
                                        <th class="text-cetner backBlack" style="text-align:center;">{{lang.status}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="text-cetner" style="text-align:center;">{{ calendarData.rows[0].id }}</td>
                                        <td class="text-cetner" style="text-align:center;">{{ calendarData.date }}</td>
                                        <td class="text-cetner" style="text-align:center;">{{ calendarData.rows[0].start_time }}</td>
                                        <td class="text-cetner" style="text-align:center;">{{ calendarData.rows[0].deadline.substr(0,10) }}</td>
                                        <td class="text-cetner" style="text-align:center;">{{ calendarData.rows[0].deadline_time }}</td>
                                        <td class="text-cetner" style="text-align:center;">{{ calendarData.rows[0].advanced_paid }}</td>
                                        <td class="text-cetner" :style="`text-align:center;${calendarData.rows[0]._status.bgColor}`">{{ calendarData.rows[0]._status[`name_`+lang.langname] }}</td>
                                    </tr>
                                    <tr>
                                        <th class="text-cetner backBlack" style="text-align:center;">{{ lang.plate_number }}</th>
                                        <th class="text-cetner backBlack" style="text-align:center;">{{lang.model}}</th>
                                        <th class="text-cetner backBlack" style="text-align:center;">{{lang.car_year}}</th>
                                        <th class="text-cetner backBlack" style="text-align:center;">{{lang.car_color}}</th>
                                        <th class="text-cetner backBlack" style="text-align:center;" colspan="3">{{lang.vin_numebr}}</th>
                                    </tr>
                                    <tr>
                                        <td class="text-cetner" style="text-align:center;">{{ calendarData.cars[0].plate_number }}</td>
                                        <td class="text-cetner" style="text-align:center;">{{ calendarData.cars[0].car_model }}</td>
                                        <td class="text-cetner" style="text-align:center;">{{ calendarData.cars[0].car_year }}</td>
                                        <td class="text-cetner" style="text-align:center;">{{ calendarData.cars[0].car_color }}</td>
                                        <td class="text-cetner" style="text-align:center;" colspan="3">{{ calendarData.cars[0].car_vin }}</td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </v-col>
                        <v-col cols="12" style="direction:rtl">
                            <v-simple-table>
                                <thead>
                                    <tr>
                                        <th class="text-cetner backBlack" style="width:120px;text-align:center;">{{lang.full_name}}</th>
                                        <td class="text-cetner" style="text-align:center;">{{ calendarData.rows[0].full_name }}</td>
                                        <th class="text-cetner backBlack" style="width:120px;text-align:center;">{{lang.mobile}}</th>
                                        <td class="text-cetner" style="width:120px;text-align:center;">{{ calendarData.rows[0].mobile }}</td>
                                    </tr>
                                    <tr v-if="calendarData.rows[0].customer_type == 2">
                                        <th class="text-cetner backBlack" style="width:120px;text-align:center;">{{lang.company_name}}</th>
                                        <td class="text-cetner" style="text-align:center;">{{ calendarData.rows[0].company_name }}</td>
                                        <th class="text-cetner backBlack" style="width:120px;text-align:center;">{{lang.vat_number}}</th>
                                        <td class="text-cetner" style="text-align:center;">{{ calendarData.rows[0].vatid }}</td>
                                    </tr>
                                    <tr v-if="calendarData.rows[0].customer_type == 2">
                                        <th class="text-cetner backBlack" style="width:120px;text-align:center;">{{lang.address}}</th>
                                        <td class="text-cetner" style="text-align:center;">{{ calendarData.rows[0].address }}</td>
                                        <th class="text-cetner backBlack" style="width:120px;text-align:center;">{{lang.crt_number}}</th>
                                        <td class="text-cetner" style="text-align:center;">{{ calendarData.rows[0].crt_number }}</td>
                                    </tr>
                                    <tr v-if="calendarData.rows[0].customer_type == 3">
                                        <th class="text-cetner backBlack" style="width:120px;text-align:center;">{{lang.entity_name}}</th>
                                        <td class="text-cetner" style="text-align:center;">{{ calendarData.rows[0].entity_name }}</td>
                                        <th class="text-cetner backBlack" style="width:120px;text-align:center;">{{ lang.branch_name }}</th>
                                        <td class="text-cetner" style="text-align:center;">{{ calendarData.rows[0].branch_name }}</td>
                                    </tr>
                                </thead>
                            </v-simple-table>
                        </v-col>
                        <v-col cols="12" style="direction:rtl">
                            <v-simple-table>
                                <thead>
                                    <tr>
                                        <th class="text-cetner backBlack" style="width:120px;text-align:center;">{{lang.notes}}</th>
                   
                                        <td class="text-cetner" style="text-align:center;">{{ calendarData.rows[0].description }}</td>
                          
                                        <th class="text-cetner backBlack" style="width:120px;text-align:center;">{{lang.worker_name}}</th>
                          
                                        <td class="text-cetner" style="width:120px;text-align:center;">{{ calendarData.rows[0].username }}</td>
                                    </tr>
                                </thead>
                            </v-simple-table>
                        </v-col>
                        <v-col cols="12" style="direction:rtl" v-for="(item,index) in calendarData.notes" :key="index">
                            <v-simple-table>
                                <thead>
                                    <tr>
                                        <th class="text-center backBlack" style="width:120px;">{{lang.note_date}}</th>
                                        <td class="text-center" style="width:140px;">{{ item.created_date }}</td>
                                        <th class="emptyBack"></th>
                                        <th class="text-center backBlack" style="width:120px;">{{lang.added_by}}</th>
                                        <td class="text-center" style="width:120px;">{{ item._created_by }}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-start" colspan="5">
                                            {{ item.description }}
                                        </td>
                                    </tr>
                                </thead>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                    <changeCalendarDateVue ref="changeCalendarDateVue" />
                    <calendarQuotationVue ref="calendarQuotationVue" />
                    <calendarCardvue ref="calendarCardvue" />
                </template>
            </b-sidebar>
        </v-form>
    </div>
</template>

<script>
import axios from 'axios'
import changeCalendarDateVue from './changeCalendarDate.vue'
import calendarQuotationVue from './calendarQuotation.vue'
import calendarCardvue from './calendarCard.vue'
export default {
    components: {changeCalendarDateVue,calendarQuotationVue,calendarCardvue},
    data(){
        return {
            calid: 1,
            calendarData: {
                date: '',
                rows: []
            }
        }
    },
    computed: {
        lang: function(){
          if(this.$cookies.get(this.$store.state.LangCooki) != null){
              if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
              return this.$store.state.lang.en;
              }else{
              return this.$store.state.lang.ar;
              }
          }
          else if(this.$store.state.deflang == 'en'){
              return this.$store.state.lang.en;
          }else{
              return this.$store.state.lang.ar;
          }
        },
    },
    created(){
        this.getCurrentCards();
    },
    methods:{
        printPdf(){
            window.open('./api/print.php?calendar='+this.calid);
        },
        openWhatsAPP(){
            window.open('https://wa.me/'+this.calendarData.rows[0]['mobile']);
        },
        resetAllValues(){
            // console.log("sdssssssssss",[this.calendarData.rows[0].id,this.calid]);
            this.$refs.changeCalendarDateVue.description = '';
            this.$refs.changeCalendarDateVue.calid = this.calid;
            this.$refs.changeCalendarDateVue.getCurrentCards();

            this.$refs.calendarQuotationVue.calid = this.calendarData.rows[0].id,
            this.$refs.calendarQuotationVue.descriptions = []
            this.$refs.calendarQuotationVue.conditions = []
            this.$refs.calendarQuotationVue.customerid = this.calendarData.rows[0].id
            this.$refs.calendarQuotationVue.mobile = this.calendarData.rows[0].mobile
            this.$refs.calendarQuotationVue.userid = this.calendarData.rows[0].userid
            this.$refs.calendarQuotationVue.plate_number = this.calendarData.rows[0].plate_number
            this.$refs.calendarQuotationVue.newRow = {
                description: '',
                price: '',
                userid: this.calendarData.rows[0].userid
            }

            this.$refs.calendarCardvue.calid = this.calendarData.rows[0].id,
            this.$refs.calendarCardvue.quotid = this.calendarData.rows[0].quotid,
            this.$refs.calendarCardvue.descriptions = []
            this.$refs.calendarCardvue.conditions = []
            this.$refs.calendarCardvue.customerid = this.calendarData.rows[0].id
            this.$refs.calendarCardvue.mobile = this.calendarData.rows[0].mobile
            this.$refs.calendarCardvue.userid = this.calendarData.rows[0].userid
            this.$refs.calendarCardvue.plate_number = this.calendarData.rows[0].plate_number
            this.$refs.calendarCardvue.getQuot()
            this.$refs.calendarCardvue.newRow = {
                description: '',
                price: '',
                userid: this.calendarData.rows[0].userid
            }
        },
        resetQuot(){
         
            this.$refs.changeCalendarDateVue.description = '';
            this.$refs.changeCalendarDateVue.calid = this.calid;
            this.$refs.changeCalendarDateVue.getCurrentCards();
        },
        async getCurrentCards(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            let cook = this.$cookies.get(this.$store.state.COOKIEPhase);
            post.append('type',"getCalendar");
            post.append('auth',cook)
            post.append('lang',this.$cookies.get(this.$store.state.LangCooki))
            post.append('data[id]',this.calid)
            const response = await axios.post(this.$store.state.SAMCOTEC.r_path,post)
            // console.log(response.data.results.data)
            if(response && response.data && response.data.results && response.data.results.data){
                const res = response.data.results.data;
                this.calendarData = res;
            }
        }
    }
}
</script>

<style>
.rowDiv{
    width:48%;
    margin-inline-end: 1%;
    padding:5px;
}
.rowDivFull{
    width:98%;
    margin-inline-end: 2%;
    margin-inline-start: 1%;
    padding:5px;
}
.myBtn{
    flex-wrap: wrap;
    display:flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: stretch;
    margin-top:5px;
}
.mySubBtn{
    flex-wrap: wrap;
    display:none;
    flex-direction: row;
    justify-content: start;
    justify-items: center;
    align-items: center;
    margin-top:5px;
}
.btnRow{
    min-width:100px;
    padding:5px 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
    margin-inline-end:5px;
    border:2px solid #fff;
    cursor: pointer;
    font-weight: bolder;
    overflow-anchor: none;
    border-radius: 5px;
    margin-top:10px;
    margin-bottom:10px;
    margin-inline-end:10px !important;
}
.btnRowF{
    min-width:120px;
    padding:5px 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
    margin-inline-end:5px;
    border:2px solid #fff;
    cursor: pointer;
    overflow-anchor: none;
    border-radius: 5px;
    margin-top:10px;
    margin-bottom:10px;
}
.c_blue{
    background:blue !important;
    color:#fff !important;
}
.c_darkblue{
    background:darkblue !important;
    color:#fff !important;
}
.c_green{
    background:green !important;
    color:#fff !important;
}
.c_red{
    background:red !important;
    color:#fff !important;
}
.c_darkslategrey{
    background:darkslategrey !important;
    color:#fff !important;
}
.c_darkgreen{
    background:darkgreen !important;
    color:#fff !important;
}
.c_darkcyan{
    background:darkcyan !important;
    color:#fff !important;
}
.c_darkolivegreen{
    background:darkolivegreen !important;
    color:#fff !important;
}
.c_darkslateblue{
    background:darkslateblue !important;
    color:#fff !important;
}
.c_darkorange{
    background:darkorange !important;
    color:#fff !important;
}
.c_darkorchid{
    background:darkorchid !important;
    color:#fff !important;
}
.c_lightgray{
    background:#d1d1d1 !important;
    color:#000 !important;
}
.c_black{
    background:#000 !important;
    color:#fff !important;
}
.c_lightgray i{
    display:none;
}
</style>